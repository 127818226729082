@keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .animate-shimmer {
    animation: shimmer 1.5s infinite;
  }
  
  /* Add animation delays for staggered effects */
  .delay-50 {
    animation-delay: 0.05s;
  }
  
  .delay-75 {
    animation-delay: 0.075s;
  }
  
  .delay-100 {
    animation-delay: 0.1s;
  }
  
  .delay-125 {
    animation-delay: 0.125s;
  }
  
  .delay-150 {
    animation-delay: 0.15s;
  }
  
  .delay-175 {
    animation-delay: 0.175s;
  }
  
  .delay-200 {
    animation-delay: 0.2s;
  }
  
  .delay-225 {
    animation-delay: 0.225s;
  }
  
  .delay-250 {
    animation-delay: 0.25s;
  }
  
  .delay-275 {
    animation-delay: 0.275s;
  }
  
  .delay-300 {
    animation-delay: 0.3s;
  }
  
  .delay-350 {
    animation-delay: 0.35s;
  }
  
  .delay-400 {
    animation-delay: 0.4s;
  }
  
  /* Add floating animation for skeleton cards */
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  .animate-float {
    animation: float 3s ease-in-out infinite;
  }
  
  /* Add pulse animation with different intensity */
  @keyframes pulse-soft {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  
  .animate-pulse-soft {
    animation: pulse-soft 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  } 